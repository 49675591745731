import React, { useEffect } from "react";
import fbTrack from "../../../components/_helpers";

function SpotifyRedirect(props) {
  useEffect(() => {
    // https://open.spotify.com/track/3DGEPRckKThdoeTpSeivHy
    if (typeof window !== "undefined") {
      let type = props.params.type;
      console.log("type:", type);
      let pathname = props.params.spotifyRedirect;
      console.log("pathname:", pathname);

      let isAlbum = type === "album";
      let isTrack = type === "track";
      let isPlaylist = type === "playlist";

      if (pathname && pathname.length < 40 && (isAlbum || isTrack || isPlaylist)) {
        window.location.href = `https://open.spotify.com/${type}/${pathname}`
        fbTrack("trackCustom", `truevined-spotify-${type}-visit-redirect`);
      } else {
        window.location.href = 'https://open.spotify.com/artist/256m8qrr1OX3cxhHpnsNJe'
        fbTrack("trackCustom", "truevined-spotify-artist-visit-redirect");
      }
    }
  }, []);

  return <div></div>;
}

export default SpotifyRedirect;
